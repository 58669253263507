<template>
    <div class="container-fluid d-flex pt-3 flex-wrap main-wrapper" style="background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/66096b97-21ef-45e6-3246-81ddd53dd000/public'); min-height: 100vh; height: auto; background-size: cover;">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-lg-12 col-12 p-0 b-5">
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0 text-white">
                                {{
                                    $store.state.language === "en"
                                        ? "Deposit"
                                        : "ငွေသွင်း"
                                }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form>
                        <h6 class="heading-small text-white">
                            {{
                                $store.state.language === "en"
                                    ? "Account information"
                                    : "အကောင့်ဆိုင်ရာ အချက်အလက်များ"
                            }}
                        </h6>
                        <span class="heading-small text-muted">{{
                                    $store.state.language === "en"
                                        ? "User's Balance"
                                        : "လက်ကျန်ငွေ"
                                }} - </span>
                        <span>
                          <b class="text-danger">{{ Number($store.state.amount).toLocaleString() }}</b>
                          <span class="text-muted pl-2">{{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }}</span>
                        </span>
                        <div class="pl-lg-4 mt-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label text-white"
                                            for="input-username"
                                            >{{
                                                $store.state.language === "en"
                                                    ? "Choose Payment Type"
                                                    : "ငွေပေးချေမူပုံစံ ရွေးချယ်ပါ"
                                            }}</label
                                        >
                                        <AccountSelect
                                            :data="selected_account"
                                            field="account_id"
                                            :accounts="accounts"
                                        />
                                        <span class="text-danger">{{
                                            errors.account
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 p-0 d-flex flex-wrap"
                                v-if="account.length !== 0"
                            >
                                <div class="col-lg-6 col-12 py-5">
                                    <h3 style="color: #46f49b !important; font-weight: 600;">{{ account[0].name }}</h3>
                                    <div
                                        class="
                                            number-container
                                            d-flex
                                            py-3
                                            my-2
                                            text-white
                                        "
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Account Name"
                                                : "အကောင့် အမည်"
                                        }}
                                        :
                                        <h3 class="m-0"  style="color: #46f49b !important; font-weight: 600;">
                                            &nbsp;{{ account[0].account_name }}

                                        </h3>
                                    </div>
                                    <div
                                        class="
                                            number-container
                                            d-flex
                                            py-3
                                             text-white
                                            my-2
                                        "
                                    >
                                        {{
                                            $store.state.language === "en"
                                                ? "Number"
                                                : "အကောင့် နံပါတ်"
                                        }}
                                        :
                                        <h3 class="m-0" id="textToCopy"  style="color: #46f49b !important; font-weight: 600;">
                                            &nbsp;{{ account[0].number }}
                                        </h3>
                                        <span class="ml-5 text-danger badge badge-danger" @click="copyText">Copy</span>
                                    </div>
                                    <b class="text-sm text-white">{{
                                        $store.state.language === "en"
                                            ? "Please charge amount to that account and save voucher screen shot and put that screenshot to file input."
                                            : "ဖော်ပြထားသောအကောင့်ကို ငွေလွဲပေးပါ။ ရရှိလာတဲ့ငွေပေးချေမူအောင်မြင်မူ ကို မှတ်တမ်းတင် (screenshot) ရိုက်ထားပါ။ ထိုမှတ်တမ်းတင်ပုံ (screenshot) ကို admin ဆီသို့ upload ၍ပို့ပေးပါ။"
                                    }}</b>
                                </div>
                            </div>
                        </div>
                        <!-- <hr class="my-4" />d -->
                        <div class="pl-lg-4">
                            <div class="row">
                                <!-- <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label">{{
                                            $store.state.language === "en"
                                                ? "Screenshot"
                                                : "ငွေပေးချေမူ ဓာက်ပုံ"
                                        }}</label>

                                        <div class="custom-file d-flex">
                                            <van-uploader
                                                class="mx-auto"
                                                v-model="imageUploader"
                                                :max-count="1"
                                            />
                                        </div>
                                        <span class="text-danger">{{
                                            errors.image
                                        }}</span>
                                    </div>
                                </div> -->
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label text-white"
                                            for="input-email"
                                            >{{
                                                $store.state.language === "en"
                                                    ? "Amount"
                                                    : "ငွေပမာဏ"
                                            }}</label
                                        >
                                        <span class="text-danger text-xs">{{
                                            $store.state.language === "en"
                                                ? " ( Minimum amount 2,000 )"
                                                : " ( အနည်းဆုံး 2,000 )"
                                        }}</span>
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control"
                                            v-model="depositData.amount"
                                            placeholder="Amount"
                                            style="border-radius: 8px; border: none; background-color: rgba(255,255,255,0.2) !important; color: #46f49b !important; font-weight: 600; box-shadow: none;"
                                        />
                                        <span class="text-danger">{{
                                            errors.amount
                                        }}</span>


                                        

                                    </div>
                                    <div class="d-flex col-12 flex-wrap p-0 mb-4">
                                        <span v-show="false">{{ testing }} </span>
                                        <div class="col-4 p-0 pr-1 py-1" @click="depositCustomAmount(2000)">
                                            <div class="amount-cont text-white py-2 text-center w-100" :class="depositData.amount === 2000 ? 'active' : ''">
                                                2,000 MMK
                                            </div>
                                        </div>
                                        
                                        <div class="col-4 p-0 px-1 py-1" @click="depositCustomAmount(5000)">
                                            <div class="amount-cont text-white py-2 text-center w-100" :class="depositData.amount === 5000 ? 'active' : ''">
                                                5,000 MMK
                                            </div>
                                        </div>
                                        
                                        <div class="col-4 p-0 pl-1 py-1" @click="depositCustomAmount(10000)">
                                            <div class="amount-cont text-white py-2 text-center w-100" :class="depositData.amount === 10000 ? 'active' : ''">
                                                10,000 MMK
                                            </div>
                                        </div>

                                        <div class="col-4 p-0 pr-1 py-1" @click="depositCustomAmount(30000)">
                                            <div class="amount-cont text-white py-2 text-center w-100" :class="depositData.amount === 30000 ? 'active' : ''">
                                            30,000 MMK
                                            </div>
                                        </div>

                                        <div class="col-4 p-0 px-1 py-1" @click="depositCustomAmount(50000)">
                                            <div class="amount-cont text-white py-2 text-center w-100" :class="depositData.amount === 50000 ? 'active' : ''">
                                                50,000 MMK
                                            </div>
                                        </div>

                                        <div class="col-4 p-0 pl-1 py-1" @click="depositCustomAmount(100000)">
                                            <div class="amount-cont text-white py-2 text-center w-100" :class="depositData.amount === 100000 ? 'active' : ''">
                                                100,000 MMK
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pl-lg-4">
                            <div class="form-group">
                                <label class="form-control-label text-white">{{
                                    $store.state.language === "en"
                                        ? "Last Transaction Id 7"
                                        : "လုပ်ဆောင်မှုအမှတ်စဉ် (နောက်ဆုံး ၇ လုံး)"
                                }}</label>
                                <input
                                    type="number"
                                    id="input-email"
                                    class="form-control"
                                    placeholder="Last Transaction Id 7"
                                    v-model="depositData.remark"
                                    style="border-radius: 8px; border: none; background-color: rgba(255,255,255,0.2) !important; color: #46f49b !important; font-weight: 600; box-shadow: none;"
                                />
                                <span class="text-danger">{{
                                    errors.remark
                                }}</span>
                            </div>

                            <van-radio-group v-model="depositData.is_bonus" class="d-flex justify-content-center mb-3" v-if="is_promotion">
                                <van-radio class="mx-4 my-1 mb-4" style="color: white !important;" :name="true" checked-color="#ee0a24"> {{ $store.state.language === 'en' ? 'Claim Bonus Point' : 'ဘောနပ် ယူမည်။' }} </van-radio>
                                <van-radio class="mx-4 my-1 mb-4" style="color: white !important;" :name="false" checked-color="#ee0a24">{{ $store.state.language === 'en' ? 'No Bonus Point' : 'ဘောနပ် မယူပါ။' }}</van-radio>
                            </van-radio-group>

                            
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn btn-success text-dark"
                                    @click="sendDepositData"
                                >
                                    <div v-if="!isConfirm">
                                        {{
                                            $store.state.language === "en"
                                                ? "Confirm"
                                                : "အတည်ပြုမည်"
                                        }}
                                    </div>

                                    <Loading
                                        color="#ffffff"
                                        v-if="isConfirm"
                                        size="24"
                                        class="px-4 py-0"
                                    />
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';
import AccountSelect from './components/AccountSelect.vue';

export default {
  components: { AccountSelect, Loading },
  data() {
    return {
      selected_account: {},
      accounts: [],
      account: [],
      imageUploader: [],
      depositData: {
        is_bonus: true
      },
      is_promotion: true,
      errors: {},
      isConfirm: false,
      testing: 0,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    depositCustomAmount(number) {
        this.depositData.amount = number;
        this.testing = number;
    },
    copyText() {
      // Get the text to copy
      const textToCopy = document.getElementById('textToCopy').innerText;

      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;

      // Append the textarea to the DOM
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();

      // Execute the 'copy' command
      document.execCommand('copy');

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      alert('You have copy Phone Number');
    },
    async fetchAccounts() {
      try {
        const res = await axios.get('/accounts', {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        this.accounts = res.data.data;
        this.depositData.is_bonus = res.data.is_promotion;
        this.is_promotion = res.data.is_promotion;

        // Group objects by name
        // const groupedByName = this.accounts.reduce((acc, obj) => {
        //     acc[obj.name] = acc[obj.name] || [];
        //     acc[obj.name].push(obj);
        //     return acc;
        // }, {});

        // // Get a random object from each group
        // const getRandomObjectFromGroup = (group) => {
        //     const randomIndex = Math.floor(Math.random() * group.length);
        //     return group[randomIndex];
        // };

        // // Collect the random objects into a single array
        // this.accounts = Object.keys(groupedByName).map(name => getRandomObjectFromGroup(groupedByName[name]));

      } catch (error) {
        console.log(error);
      }
    },
    async sendDepositData() {
      try {
        const authUser = await this.fetchUser();
        this.errors = {};
        if (!this.selected_account.account_id) {
          this.errors.account = this.$store.state.language === 'en'
            ? 'Please pick account type'
            : 'အကောင့်အမျိုးအစား ရွေးချယ်ပေးပါ။';
          return false;
        }
        if (!this.depositData.remark || this.depositData.remark.length < 7) {
          this.errors.remark = this.$store.state.language === 'en'
            ? 'Please enter last transaction id 7'
            : 'ငွေစာရင်းနောက်ဆုံးနံပါတ်  7 လုံး ထည့်ပေးပါ။';
          return false;
        }

        if (!this.depositData.amount) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter amount'
            : 'ငွေပမာဏထည့်ပေးပါ။';
          return false;
        }
        if (!this.depositData.amount) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter amount'
            : 'ငွေပမာဏထည့်ပေးပါ။';
          return false;
        }
        if (this.depositData.amount < 2000) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter at least 2000 MMK'
            : 'ငွေပမာဏအနည်းဆုံး 2000 ထည့်ပေးပါ။';
          return false;
        }
        // if (!this.depositData.remark) {
        //   this.errors.remark = this.$store.state.language === 'en'
        //     ? 'Please enter last transaction id 6'
        //     : 'Please enter last transaction id 6';
        //   return false;
        // }
        this.isConfirm = true;
        // if (this.imageUploader[0]) {
        //   this.depositData.transaction_image = this.imageUploader[0].content;
        // }
        this.depositData.account_id = this.account[0].id;
        this.depositData.date = this.currentTime;
        const res = await axios.post('/deposits', this.depositData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        // this.imageUploader = [];
        this.depositData = {};
        this.selected_account.account_id = null;
        Toast.success(
          this.$store.state.language === 'en'
            ? 'Success'
            : 'အောင်မြင်သည်။',
        );
        this.isConfirm = false;
      } catch (error) {
        Toast.fail(
          this.$store.state.language === 'en' ? 'Fail' : 'ကျရှုံးသည်။',
        );
        this.isConfirm = false;
      }
      return true;
    },
    createDateWithTimeZone() {
      const moment = require('moment-timezone');
      const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
      // Extract individual components from moment object
      const year = dateInTimeZone.year();
      const month = dateInTimeZone.month();
      const day = dateInTimeZone.date();
      const hour = dateInTimeZone.hour();
      const minute = dateInTimeZone.minute();
      const second = dateInTimeZone.second();
      const millisecond = dateInTimeZone.millisecond();

      // Create a new Date object with the extracted components
      const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
      return newDateObject;
    },
  },
  watch: {
    'selected_account.account_id': function () {
      this.account = this.accounts.filter(
        (account) => account.id === this.selected_account.account_id,
      );
    },
  },
  computed: {
    currentTime() {
      return moment(this.createDateWithTimeZone()).format('lll');
    },
  },
  async mounted() {
    this.fetchAccounts();
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    if (authUser && !authUser.is_verified) {
      this.$router.push('/auth/verify');
    }
  },
};
</script>
<style scoped>
.amount-cont {
    border: 1px solid #ccced0;
    border-radius: 10px;
}

.amount-cont.active {
    background: #dee2e6;
    color: black !important;
    border: 1px solid 
}

.image-container {
    width: 200px;
    height: 200px;
}
.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.number-container {
    border-radius: 5px;
    background: rgba(255,255,255,0.2);
    justify-content: center;
}
.custom-file {
    height: auto;
}
@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
}
</style>
