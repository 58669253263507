<template>
  <div
    style="background-image: url('https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/66096b97-21ef-45e6-3246-81ddd53dd000/public'); min-height: 100vh; height: auto; background-size: cover;"
  >
    <div class="login-cont container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7 d-flex flex-column px-0 mt-2">
          <img
            class="mx-auto my-3"
            src="/logo.jpg"
            alt=""
            @click="$router.push('/home')"
            style="width: 150px; height: 150px"
          />

          <!-- <div class="header-body text-center">
            <div class="row justify-content-center">
              <div class="col-xl-8 col-lg-8 col-md-8 px-5 pb-5">
                <img src="/build/assets/img/images/image_4.png" alt="">
              </div>
            </div>
          </div> -->


          <div class="card border-0 mb-0 auth-card px-0" style="background: transparent !important">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center mb-4">
                <div class="error text-white d-flex" v-if="error">
                  <span class="mx-auto">
                    {{ error }}
                  </span>
                </div>
              </div>

              <form role="form mx-0 px-0">
                <div class="form-group mb-3">
                  <div
                    class="input-group input-group-merge input-group-alternative col-10 mx-auto"
                    style="margin-bottom: -50px; z-index: 100; box-shadow: none !important;"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="background: transparent;"
                        ><i class="fas fa-user mx-auto my-1" style="font-size: 16px; color: black;"></i></span>
                    </div>
                    <input
                      class="pl-2"
                      :placeholder="$store.state.language === 'en' ? 'Your Phone' : 'သင့် အမည်ဖြည့်ရန်'"
                      style="background: transparent; border: none; width: 80%;"
                      type="text"
                      v-model="userData.name"
                      @keyup.enter="focusOnPasswordInputBox"
                    />
                  </div>
                  <img class="col-12" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/8a78afbc-81c6-4186-ea60-d7292745fb00/public" style="height: 60px;" alt="">
                </div>

                <!-- <div class="form-group mb-3 pt-3">
                  <div
                    class="input-group input-group-merge input-group-alternative col-10 mx-auto"
                    style="margin-bottom: -50px; z-index: 100; box-shadow: none !important;"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="background: transparent;"
                        ><i class="fas fa-user mx-auto my-1" style="font-size: 16px; color: black;"></i></span>
                    </div>
                    <input
                      class="pl-2"
                      placeholder="Nick Name"
                      style="background: transparent; border: none;"
                      type="text"
                      v-model="userData.nickname"
                    />
                  </div>
                  <img class="col-12" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/8a78afbc-81c6-4186-ea60-d7292745fb00/public" alt="">
                </div> -->


                <small class="text-danger">{{ errors.phone }}</small>
                <div class="form-group mb-3 pt-3">
                  <div
                    class="input-group input-group-merge input-group-alternative col-10 mx-auto"
                    style="margin-bottom: -50px; z-index: 100; box-shadow: none !important;"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="background: transparent;"
                        ><i class="fas fa-phone mx-auto my-1" style="font-size: 16px; color: black;"></i></span>
                    </div>
                    <input
                      class="pl-2"
                      :placeholder="$store.state.language === 'en' ? 'Your Phone' : 'သင့် ဖုန်းနံပါတ်ဖြည့်ရန်'"
                      style="background: transparent; border: none; width: 80%;"
                      type="text"
                      v-model="userData.phone"
                      @keyup.enter="focusOnPasswordInputBox"
                    />
                  </div>
                  <img class="col-12" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/8a78afbc-81c6-4186-ea60-d7292745fb00/public" style="height: 60px;" alt="">
                </div>

                <div class="form-group mb-3 pt-3">
                  <div
                    class="input-group input-group-merge input-group-alternative col-10 mx-auto"
                    style="margin-bottom: -50px; z-index: 100; box-shadow: none !important;"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="background: transparent;"
                        ><i class="fas fa-users mx-auto my-1" style="font-size: 16px; color: black;"></i></span>
                    </div>
                    <input
                      class="pl-2"
                      :placeholder="$store.state.language === 'en' ? 'Referral Code (Option)' : 'အေးဂျင့်မိတ်ဆက်ကုတ် ရှိပါက ဖြည့်ရန်'"
                      style="background: transparent; border: none; width: 85%;"
                      type="text"
                      v-model="userData.referralCode"
                      @keyup.enter="focusOnPasswordInputBox"
                      :disabled="$route.query.code ? true : false"
                    />
                  </div>
                  <img class="col-12" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/8a78afbc-81c6-4186-ea60-d7292745fb00/public" style="height: 60px;" alt="">
                </div>


                <small class="text-danger">{{ errors.password }}</small>
                <div class="form-group mb-3 pt-3">
                  <div
                    class="input-group input-group-merge input-group-alternative col-10 mx-auto"
                    style="margin-bottom: -50px; z-index: 100; box-shadow: none !important;"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="background: transparent;"
                        ><i class="fas fa-key mx-auto my-1" style="font-size: 16px; color: black;"></i></span>
                    </div>
                    <input
                      class="pl-2"
                      :placeholder="$store.state.language === 'en' ? 'Password' : 'လျှို့ဝှက်ကုတ်ဖြည့်ရန်'"
                      style="background: transparent; border: none; width: 80%;"
                      :type="isFirstPasswordShow ? 'text' : 'password'"
                      ref="password"
                      v-model="userData.password"
                      @keyup.enter="loginWithPhone"
                    />
                    <i
                      class="far fa-eye showable-password"
                      v-if="isFirstPasswordShow"
                      @click="isFirstPasswordShow = !isFirstPasswordShow"
                    ></i>
                    <i
                      v-if="!isFirstPasswordShow"
                      class="fas fa-eye-slash showable-password"
                      @click="isFirstPasswordShow = !isFirstPasswordShow"
                    ></i>
                  </div>
                  <img class="col-12" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/8a78afbc-81c6-4186-ea60-d7292745fb00/public" style="height: 60px;" alt="">
                </div>


                <small class="text-danger">{{ errors.confirmPassword }}</small>
                <div class="form-group pt-3">
                  <div
                    class="input-group input-group-merge input-group-alternative col-10 mx-auto"
                    style="margin-bottom: -50px; z-index: 100; box-shadow: none !important;"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="background: transparent;"
                        ><i class="fas fa-key mx-auto my-1" style="font-size: 16px; color: black;"></i></span>
                    </div>
                    
                    <input
                      class="pl-2"
                      :placeholder="$store.state.language === 'en' ? 'Confirm Password' : 'အတည်ပြု လျှို့ဝှက်ကုတ်ဖြည့်ရန်'"
                      style="background: transparent; border: none; width: 80%;"
                      :type="isSecondPasswordShow ? 'text' : 'password'"
                      ref="password"
                      v-model="userData.confirmPassword"
                      @keyup.enter="loginWithPhone"
                    />
                    <i
                      class="far fa-eye showable-password"
                      v-if="isSecondPasswordShow"
                      @click="isSecondPasswordShow = !isSecondPasswordShow"
                    ></i>
                    <i
                      v-if="!isSecondPasswordShow"
                      class="fas fa-eye-slash showable-password"
                      @click="isSecondPasswordShow = !isSecondPasswordShow"
                    ></i>
                  </div>
                  <img class="col-12" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/8a78afbc-81c6-4186-ea60-d7292745fb00/public" style="height: 60px;" alt="">
                </div>

                <van-radio-group v-model="userData.gender" class="d-flex justify-content-center">
                  <van-radio class="mx-4 my-3 mb-4" style="color: white !important;" name="male" checked-color="#ee0a24"> {{ $store.state.language === 'en' ? 'Male' : 'အမျိုးသား' }} </van-radio>
                  <van-radio class="mx-4 my-3 mb-4" style="color: white !important;" name="female" checked-color="#ee0a24">{{ $store.state.language === 'en' ? 'Female' : 'အမျိုးသမီး' }}</van-radio>
                  
                </van-radio-group>
                
                <div class="text-center d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn"
                    style="color: black; background: #EBE73F; border-radius: 8px !important; padding: 3px 25px !important; height: 30px !important; border: 4px solid #AEA511;"
                    @click="loginWithPhone"
                  >
                    <div v-if="!isSignin" class="text-nowrap">
                      {{
                        $store.state.language === 'en'
                          ? 'Sign Up'
                          : 'အကောင့် အသစ်ဖွင့်မည်။'
                      }}
                    </div>
                    <Loading
                      color="#000000"
                      v-if="isSignin"
                      size="18"
                      class="px-4 py-0"
                    />
                  </button>

                  <button
                    type="button"
                    class="btn"
                    style="color: black; background: #17B90F; border-radius: 8px !important; padding: 3px 25px !important; height: 30px !important; border: 4px solid #028118;"
                    @click="$router.push('/auth/login')"
                  >
                    <div class="text-nowrap">
                      {{
                        $store.state.language === 'en'
                          ? 'Sign In'
                          : 'အကောင့် ဝင်မည်။'
                      }}
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Loading },
  data() {
    return {
      userData: {
      gender: 'male',
      referralCode: this.$route.query.code || null,
      },
      name: null,
      password: null,
      error: false,
      errors: {},
      isSignin: false,
      isShow: false,
      isFirstPasswordShow: true,
      isSecondPasswordShow: true,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    focusOnPasswordInputBox() {
      this.$refs.password.focus();
    },
    /**
     * login with phone
     */
    async loginWithPhone() {
      this.isSignin = true;
      this.error = null;
      if (!this.userData.name) {
        this.error =
          this.$store.state.language === 'en'
            ? 'Required User Name'
            : 'User name လိုအပ်ပါသည်။';
        this.isSignin = false;
        return false;
      }
      if (!this.userData.phone) {
        this.error =
          this.$store.state.language === 'en'
            ? 'Required Phone Number'
            : 'Phone Number လိုအပ်ပါသည်။';
        this.isSignin = false;
        return false;
      }

      const phonePattern = /^(09\d{7,14}|\+959\d{7,14})$/;
      if (!phonePattern.test(this.userData.phone)) {
        console.log('FAIL')
        this.error =
          this.$store.state.language === 'en'
            ? 'Invalid Phone Number'
            : 'ဖုန်းနံပါတ် မမှန်ကန်ပါ။';
        this.isSignin = false;
        return false;
      }

      if (!this.userData.password) {
        this.error =
          this.$store.state.language === 'en'
            ? 'Required Password'
            : 'Password လိုအပ်ပါသည်။';
        this.isSignin = false;
        return false;
      }
      if (this.userData.password !== this.userData.confirmPassword) {
        this.error =
          this.$store.state.language === 'en'
            ? 'Password Not Match'
            : 'Password Not Match';
        this.isSignin = false;
        return false;
      }
      try {
        this.userData.roles = 'normal';
        this.userData.type = 'login';

        const res = await this.axios.post(
            '/auth/register',
            {...this.userData},
          );
        if(res.data.status === 'success') {
          this.$router.push('/home');
        }

          localStorage.setItem('username', res.data.data.name);
          localStorage.setItem('password', this.userData.password);
          localStorage.setItem('token', res.data.token);

      } catch (error) {
        if (error.response && error.response.data) {
          const errors = error.response.data.errors;
          if(errors.phone) {
            this.error = errors.phone[0];
          }
          if(errors.name) this.error = errors.name[0];
        } else {
        this.error = this.$store.state.language === 'en'
            ? 'Registration Failed!'
            : 'အကောင့်ဖွင့်ခြင်း ကျရှုံးပါသည်။';
      }
      }
      this.isSignin = false;
      return true;
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (authUser && authUser.is_verified && this.$store.state.authUser.roles === 'normal')
      this.$router.push('/home');
    const userName = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    if (userName && password) {
      this.name = userName;
      this.password = password;
    }
  },
};
</script>

<style scoped>
/* Change placeholder text color for all input elements */
::placeholder {
            color: #2c2b2b; /* Light gray color */
        }
        
.login-cont {
  padding-top: 3rem;
}

.showable-password {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1000;
}

.new-account-text {
  cursor: pointer;
}

.error {
  margin-bottom: 30px;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: red;
  border: 1px solid red;
  border-radius: 8px;
  padding: 0px 15px;
  background: #ff000014;
  font-weight: bold;
}

.auth-card {
  background: #eeecec;
  /* color: black */
}
</style>
