var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 py-4"},[_vm._m(0),_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_c('li',[_c('div',{staticClass:"d-flex justify-content-center my-4",staticStyle:{"position":"fixed","bottom":"0","left":"0","right":"0"}},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.logout}},[_vm._v("အတည်မပြုပါ")]),_c('button',{staticClass:"btn btn-primary customzie-color",on:{"click":_vm.toHome}},[_vm._v("အတည်ပြုပါသည်")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"text-dark text-center"},[_vm._v(" မန္တလာသူ မှထုတ်ပြန်သော "),_c('br'),_vm._v(" စည်းကမ်းချက်များ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁) ")]),_vm._v("ဘော်ဒီများကိုအနည်းဆုံး (1000) ကျပ်မှ စတင်ပြီး ဆော့ကစားနိုင်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၂) ")]),_vm._v("ပွဲစဉ်များကို ပွဲကြီး ပွဲသေး အရောင်နဲ့ ပိုင်းခြားပြပြီး အကောက်ကို 5% သာ ကောက်ခံပါသည်။"),_c('br'),_vm._v(" "),_c('b',[_vm._v("အကောက် - ")]),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("ပွဲကြီး - 5%")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("ပွဲသေး - 5%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၃) ")]),_vm._v("မောင်း အနည်း ဆုံး (500) ကျပ်မှ ကစားနိုင်သည်။ "),_c('br'),_vm._v(" "),_c('b',[_vm._v(" မောင်းအကောက် -")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("2 သင်း - 15%")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("3 သင်း - 11 သင်း > 20%")]),_vm._v(" "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၄) ")]),_vm._v("အနိုင် အရှုံး သရေ(1x2)၊ ဂိုးခန့်မှန်းရလဒ်(focus)တို့ကို အနည်းဆုံး(၁၀၀၀)ကျပ်မှ စတင်ကစားနိုင်ပါသည်။ "),_c('br'),_vm._v(" "),_c('b',[_vm._v("အကောက် - ")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("1x2 - 5%")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("Focus - 5%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၅) ")]),_vm._v("သက်မှတ်ချိန် တစ်ရက်တာအတွင်း ပွဲပျက်(သို့မဟုတ်) မကန်သောပွဲစဉ်များ ရလဒ်မထွက်သောပွဲစဉ်များအား အလျော်အစားမလုပ်ပဲ ထိုးငွေအား ပြန်ပေးပါမည်။ (မှတ်ချက် - သက်မှတ်ပွဲစချိန်မှ 2 နာရီ အတွင်း မကန်သော အချိို့ပွဲစဉ်များအား ပွဲပျက်ဟု သတ်မှတ်နိုင်သည်။) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၆) ")]),_vm._v("အချိန်စောကန်သွားသော ပွဲစဉ်များအား မန္တလာသူ Application တွင်မူလအချိန်အတိုင်း အချိန်မပြောင်းပဲ ဖွင့်ထားပါက ပွဲပျက်သက်မှတ်ပါမည်။ အချိန်ပြောင်းလဲထားပါက အလျော်အစားပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၇) ")]),_vm._v("ဂိုးရလဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting Company မှဂိုးရလဒ်ဖြင့် အလျော်အစားပြုလုပ်ပါမည် ( Ibet789 / Bet365 ) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၈) ")]),_vm._v("နည်းပညာချွတ်ယွင်း၍ အခြားသော Betting Company များနှင့် ပေါက်ကြေးလွန်စွာကွဲလွဲနေပါက Cancal match ပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၉) ")]),_vm._v("နည်းပညာချွတ်ယွင်း၍ အလျော်အစားမှားပြုလုပ်ထားသော ဘောင်ချာများအား နောက်တစ်နေ့ နေ့ခင်း 12 နာရီမတိုင်ခင် အကြောင်းကြားပေးထားသောဘောင်ချာများသာ တာဝန်ယူဖြေရှင်းပေးမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၀) ")]),_vm._v("ပုံမှန်မဟုတ်သော ပွဲစဉ်များပါရှိပါက ၎င်းပွဲစဉ်အား အလျော်အစားမပြုပဲ Cancel Match ပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၁) ")]),_vm._v("ဆော့ကစားခြင်းမရှိပဲ ငွေ အထုတ်/အသွင်း ပြုလုပ်သော အကောင့်များအား ငွေပြန်မအမ်းပဲ ပိတ်သိမ်းပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၂) ")]),_vm._v("နည်းပညာအကူအညီဖြင့်သော်လည်းကောင်း၊ အခြားမရိုးသားသည့်နည်းလမ်းများကို အသုံးပြု၍ ကစားထားသည်များကို တွေ့ရှိရပါက ငွေပြန်ထုတ်ပေးခြင်းမရှိဘဲ ခြွင်းချက်မရှိ အကောင့်ပိတ်သိမ်းပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၃) ")]),_vm._v("အငြင်းပွါးဖွယ်ရာ ကိစ္စများပေါ်ပေါက်လာပါက အများနှင့်နိူင်းယှဉ်သုံးသပ်ရသည့်အတွတ် မန္တလာသူ ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်သည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h5',{staticClass:"text-center text-danger mt-4 mb-5 pb-3"},[_vm._v(" စည်းကမ်းချက်များကို သေချာစွာဖတ်ရှုပြီး သဘောတူပါကြောင်း အတည်ပြုပေးပါ။ ")])])
}]

export { render, staticRenderFns }